
import ProjectHelper from '~/mixins/project/ProjectHelper'
import VButton from '~/components/ui/VButton'
import VInput from '~/components/ui/VInput'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import VCheckbox from '@/components/ui/VCheckbox';

const linkFile = '//gde-credit.ru/api/file/inline/ce46cde0-2e42-11ef-bb60-c7ad23d801bc'

export default {
    mixins: [ProjectHelper],
    components: {
        VInput,
        VButton,
        VCheckbox
    },
    data() {
        return {
            monthCountForShow : 6,
            scheduleLoaded: null,
            fullScheduleIsHidden: true,
            value: '',
            graph: false,
            application: null,
            fileContractPath: '',
            sum: '',
            sumError: '',
            loading: false,
            callback: null,
            isAcceptRisk: false
        }
    },
    computed: {
        ...mapGetters('profile', [
            'role',
        ]),
        ...mapState('profile', [
            'currentAccount'
        ]),
        baseUrl() {
            return this.$config.PUBLIC_API + '/file/'
        },
        fileContractUrl() {
            return this.$config.PUBLIC_API + '/pre-offer-invest/'
        },
        baseUrlContract() {
            return this.$config.PUBLIC_API + '/pre-pledge-contract-invest/'
        },
        formIsValid() {
            return !!(this.sum && !this.sumError && this.isAcceptRisk)
        },
        labelAcceptRisk() {
            switch (this.currentAccount?.type) {
                case 'individual':
                case 'entity':
                    return `Я осознаю и принимаю <a href="${linkFile}" target="_blank">риски</a>, связанные с инвестированием.`
                case 'personal': {
                    if (!this.currentAccount.is_qualified) {
                        return `Я осознаю и принимаю <a href="${linkFile}" target="_blank">риски</a>, связанные с инвестированием, а также подтверждаю, что сумма моих инвестиций на всех платформах в текущем календарном году не превышает 600 000 рублей.`
                    }
                    return `Я осознаю и принимаю <a href="${linkFile}" target="_blank">риски</a>, связанные с инвестированием.`
                }
                default: return ''
            }
        }
    },
    watch: {
        sum(value) {
            this.graph = false
            if (value) this.validateSum(parseInt(value.replace(/\s/g, '')));
        }
    },
    methods: {
        ...mapActions('payment', [
            'getShedule',
        ]),
        ...mapActions('service', [
            'invest'
        ]),
        ...mapMutations('service', [
            'setPdfId'
        ]),
        toggleFullSchedule(){
            this.fullScheduleIsHidden = !this.fullScheduleIsHidden
        },
        async loadSchedule(){
            this.scheduleLoaded = null;
            if (this.formIsValid) {
                await this.$axios.post(`/payments-calculator/${this.application.id}`, {
                    amount: parseInt(this.sum.replace(/\s/g,''), 10)
                }).then(res => {
                    this.scheduleLoaded = res.data.item
                }).catch(err => {
                    this.scheduleLoaded = null;
                })
            }
        },
        async downloadShedulePDF () {
            const res = await this.getShedule({
                applicationId: this.application.id,
                sum: parseInt(this.sum.replace(/\s/g,''), 10)
            })

            const blob = new Blob([res.data], {type: 'application/pdf'} )
            const data = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = data
            link.download = `График-платежей - ${new Date().toLocaleDateString()}.pdf`
            link.click()

            setTimeout(() => {
                window.URL.revokeObjectURL(data)
            }, 100)
        },
        handleChangeCost(value) {
            if (value) this.sum = parseInt(value.replace(/\s/g, '')).toLocaleString('ru-RU');
        },
        toggleGraph() {
            this.graph = !this.graph
            if(this.graph){
                this.loadSchedule();
            }
        },
        beforeOpen(event) {
            this.application = event.params.application;
            this.callback = event.params.callback || null
        },
        beforeClose() {
            this.sumError = ''
            this.sum = ''
            this.scheduleLoaded = null
            this.callback = null
        },
        validateSum(value) {
            this.sumError = value % 1000 !== 0 ?  'Сумма должна быть кратна 1000!' : ''
        },
        submit() {
            if (this.formIsValid && !this.loading) {
                this.loading = true;
                this.invest({
                    application_id: this.application.id,
                    sum: parseInt(this.sum.replace(/\s/g,''), 10)
                })
                    .then(res => {
                        if (this.callback) this.callback();
                        this.$modal.hide('project-invest');
                        this.loading = false
                        this.setPdfId(res.pdfId)
                    })
                    .catch(({ response }) => {
                        if (response?.data?.errors?.sum) {
                            this.sumError = response.data.errors.sum;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
}
